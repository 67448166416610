import PartImg1 from "../assets/images/resources/partImg-ELCA-gray.png";
import PartImg2 from "../assets/images/resources/partImg-RSC-gray.png";
import PartImg3 from "../assets/images/resources/partImg-RMIT-gray.png";
import PartImg4 from "../assets/images/resources/partImg-HHN-gray.png";
import PartImg5 from "../assets/images/resources/partImg-GRE-gray.png";

import PartHoverImg1 from "../assets/images/resources/partImg-ELCA-hover.png";
import PartHoverImg2 from "../assets/images/resources/partImg-RSC-hover.png";
import PartHoverImg3 from "../assets/images/resources/partImg-RMIT-hover.png";
import PartHoverImg4 from "../assets/images/resources/partImg-HHN-hover.png";
import PartHoverImg5 from "../assets/images/resources/partImg-GRE-hover.png";

export const partners = [
  {
    partImg: PartImg1,
    partHoverImg: PartHoverImg1,
    partLink: "https://www.elca.ch/en",
  },
  {
    partImg: PartImg2,
    partHoverImg: PartHoverImg2,
    partLink: "https://www.rochdalespears.com/",
  },
  {
    partImg: PartImg3,
    partHoverImg: PartHoverImg3,
    partLink: "https://www.rmit.edu.au/",
  },
  {
    partImg: PartImg4,
    partHoverImg: PartHoverImg4,
    partLink: "https://www.hs-heilbronn.de/en",
  },
  {
    partImg: PartImg5,
    partHoverImg: PartHoverImg5,
    partLink: "https://www.gre.ac.uk/",
  },
];
