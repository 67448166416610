import { ReactComponent as LinkedinIcon } from "../lib/icons/Linkedin.svg";
import { ReactComponent as SkypeIcon } from "../lib/icons/SkypeContact.svg";

export const social2 = [
  {
    socialIcon: (
      <LinkedinIcon className="h-5 w-5 lg:h-8 lg:w-8 fill-accent2 hover:fill-accent"></LinkedinIcon>
    ),
    socialLink: "https://www.linkedin.com/in/huyquangdinh/",
    socialTitle: "LinkedIn"
  },
  {
    socialIcon: (
      <SkypeIcon className="h-5 w-5 lg:h-8 lg:w-8 fill-accent2 hover:fill-accent"></SkypeIcon>
    ),
    socialLink: "live:huydq1903",
    socialTitle: "Skype"
  },
];
