import ExpImg1 from "../assets/images/resources/expImg1.jpg";
import ExpImg2 from "../assets/images/resources/expImg2.jpg";
import ExpImg3 from "../assets/images/resources/expImg3.jpg";

export const workExp = [
  {
    expCount: "1",
    expDate: "Jan 2021 - Nov 2022",
    expImg: ExpImg1,
    expTitle: "Business Analyst",
    expSubTitle: "ELCA Vietnam",
    expDesc:
      "Appointed Business Analyst for bridging Swiss and Vietnamese teams, demonstrating strong analytical thinking. Improved specification quality, synchronized teams through effective communication, and streamlined projects, enhancing efficiency and introducing valuable metrics.",
  },
  {
    expCount: "2",
    expDate: "Oct 2016 - Dec 2020",
    expImg: ExpImg2,
    expTitle: "Senior Software Engineer",
    expSubTitle: "ELCA Vietnam",
    expDesc:
      "Integral in successful SharePoint projects for internal & external clients, showcasing full-stack proficiency. Contributed to key projects, applying design patterns and Agile methodologies, reducing costs, and enhancing internal management.",
  },
  {
    expCount: "3",
    expDate: "Mar 2014 - Sep 2016",
    expImg: ExpImg3,
    expTitle: "Software Developer",
    expSubTitle: "Rochdale Spears Group",
    expDesc:
      "Led the transformation of the RSC application, a pivotal asset in departmental meetings. Proficient full-stack developer, prioritizing user engagement, rigorous testing, and streamlined communication. Developed insightful dashboards for target tracking. Efficiently resolved system-wide issues.",
  }
];
