export const education = [
  {
    eduDate: "2023 - 2025",
    eduTitle: "Master of Information Technology",
    eduIns: "RMIT University - Australia",
    eduDesc: "",
  },
  {
    eduDate: "2019 - 2022",
    eduTitle: "Master of Business Information Systems",
    eduIns: "Hochschule Heilbronn - Germany",
    eduDesc: "",
  },
  {
    eduDate: "2011 - 2013",
    eduTitle: "Bachelor of Computer Science",
    eduIns: "University of Greenwich - Vietnam",
    eduDesc: "",
  },
  {
    eduDate: "2009 - 2011",
    eduTitle: "Higher Diploma: Software Engineering",
    eduIns: "APTECH Computer Education - Vietnam",
    eduDesc: "",
  },
];
