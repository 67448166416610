import { BrowserRouter, Routes, Route } from "react-router-dom";
import SmoothScroll from "../components/SmoothScroll/SmoothScroll";
import { ContactPage } from "../pages/ContactPage";
import { ErrorPage } from "../pages/ErrorPage";
import { HomePage2 } from "../pages/HomePages";
import { PortfolioDetail2, PortfolioPage } from "../pages/PortfolioPages";
import { ServicePage } from "../pages/ServicePages";

const RouterLinks = () => {
  return (
    <>
      <BrowserRouter>
        <SmoothScroll>
          <Routes>
            <Route path="/" element={<HomePage2></HomePage2>} />
            <Route path="/service" element={<ServicePage></ServicePage>} />
            <Route path="/portfolio" element={<PortfolioPage></PortfolioPage>} />
            <Route path="/portfolio/:Id" element={<PortfolioDetail2></PortfolioDetail2>} />
            <Route path="/contact" element={<ContactPage></ContactPage>} />
            <Route path="/404" element={<ErrorPage></ErrorPage>} />
            <Route path="*" element={<ErrorPage></ErrorPage>} />
          </Routes>
        </SmoothScroll>
      </BrowserRouter>
    </>
  );
};

export default RouterLinks;
