export const bottomMenu = [
  {
    bottomMenuCap: "Terms & Condition",
    bottomMenuLink: "#",
  },
  {
    bottomMenuCap: "Privacy Policy",
    bottomMenuLink: "#",
  },
];
