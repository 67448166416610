import { ReactComponent as JavaScriptIcon } from "../lib/icons/JavaScript.svg";
import { ReactComponent as HtmlIcon } from "../lib/icons/Html.svg";
import { ReactComponent as SQLIcon } from "../lib/icons/Microsoft_SQL_Server.svg";
import { ReactComponent as NETIcon } from "../lib/icons/Microsoft_.NET.svg";

export const skills = [
  {
    skillBox: "pb-10 sm:pt-5 sm:pb-10 sm:pr-10 border-r-0 sm:border-r-[1px] border-b-[1px] border-[#9bb1cf]",
    skillIcon: <NETIcon className="h-10 w-10"></NETIcon>,
    skillTitle: ".NET",
    skillDesc: "",
    skillPercent: "90%",
  },
  {
    skillBox: "pb-10 pt-10 sm:pt-5 sm:pb-10 sm:pl-10 border-b-[1px] border-[#9bb1cf]",
    skillIcon: <SQLIcon className="h-10 w-10"></SQLIcon>,
    skillTitle: "SQL",
    skillDesc: "",
    skillPercent: "90%",
  },
  {
    skillBox: "pb-10 pt-10 sm:pb-5 sm:pt-10 sm:pr-10 border-r-0 sm:border-r-[1px] sm:border-b-0 border-b-[1px] border-[#9bb1cf]",
    skillIcon: <HtmlIcon className="h-10 w-10"></HtmlIcon>,
    skillTitle: "HTML/CSS3",
    skillDesc: "",
    skillPercent: "70%",
  },
  {
    skillBox: "pt-10 sm:pb-5 sm:pt-10 sm:pl-10",
    skillIcon: <JavaScriptIcon className="h-10 w-10"></JavaScriptIcon>,
    skillTitle: "JavaScript",
    skillDesc: "",
    skillPercent: "65%",
  },
];
