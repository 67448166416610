import PortImg1 from "../assets/images/resources/portDetailImg1-1.jpg";
import PortImg2 from "../assets/images/resources/portDetailImg1-2.jpg";
import PortImg3 from "../assets/images/resources/portDetailImg1-3.jpg";
import PortImg4 from "../assets/images/resources/portDetailImg1-4.jpg";
import PortImg5 from "../assets/images/resources/portDetailImg1-5.jpg";
import PortImg6 from "../assets/images/resources/portDetailImg1-6.jpg";
import PortImg7 from "../assets/images/resources/portDetailImg1-7.jpg";
// import PortImg8 from "../assets/images/resources/portDetailImg1-8.jpg";
// import PortImg9 from "../assets/images/resources/portDetailImg1-9.jpg";

export const portDetail = [
  {
    id: 1,
    portImg: PortImg1,
    portTitle: "Business Analyst",
    portComments: "0",
    portLikes: "0",
    portCateList: [
      {
        cateItem: "Government & Public,",
        cateLink: "#",
      },
      {
        cateItem: "Technology",
        cateLink: "#",
      },
    ],
    portDesc:
      "Appointed as a Business Analyst due to my strong analytical thinking, technical background, and ability to act as a bridge between Swiss and Vietnamese teams",
    portDesc2:
      "Improved specification quality, ensuring precise business understanding and alignment",
    portDesc3:
      "Facilitated communication and demo meetings to synchronize Swiss and Vietnamese teams for project success",
    portDesc4:
      "Successfully managed projects related to civil management, criminal management, and administration management, streamlining processes and enhancing efficiency",
    portTitle2: "Key Contributions",
    portInfoList: [
      {
        infoItem: "Website:",
        infoCap: "elca.ch",
      },
      {
        infoItem: "Date:",
        infoCap: "Nov 2022",
      },
      {
        infoItem: "Services:",
        infoCap: "Business Analysis, Cross-cultural Communication, Project Management",
      },
    ],
    portInfoList2: [
      {
        infoCap: "Analytical Leadership",
      },
      {
        infoCap: "Cross-cultural Facilitation",
      },
      {
        infoCap: "Enhanced Specification Quality",
      },
      {
        infoCap: "Project Management Excellence",
      },
      {
        infoCap: "Bridging Technical and Business Perspectives",
      },
    ],
  },
  {
    id: 2,
    portImg: PortImg2,
    portTitle: "Senior Software Engineer",
    portComments: "0",
    portLikes: "0",
    portCateList: [
      {
        cateItem: "Financial,",
        cateLink: "#",
      },
      {
        cateItem: "Healthcare,",
        cateLink: "#",
      },
      {
        cateItem: "Technology",
        cateLink: "#",
      },
    ],
    portDesc:
      "Played a pivotal role in developing successful SharePoint projects, serving both internal and external clients",
    portDesc2:
      "Demonstrated full-stack expertise by supporting a variety of projects, contributing to cost reduction and improved internal management",
    portDesc3:
      "Key projects included the ELCA Intranet site, CV Tool, Training Platform, and Portfolios and Projects Management",
    portDesc4:
      "Collaborated effectively with project teams, applying best practices in design patterns and SOLID principles",
    portDesc5:
      "Utilized cutting-edge platforms to deliver innovative solutions",
    portDesc6:
      "Contributed to projects related to reinsurance management and vaccination management",
    portDesc7:
      "Adhered to Agile methodologies for enhanced project management",
    portTitle2: "Key Contributions",
    portInfoList: [
      {
        infoItem: "Website:",
        infoCap: "elca.ch",
      },
      {
        infoItem: "Date:",
        infoCap: "Dec 2020",
      },
      {
        infoItem: "Keywords:",
        infoCap: "SharePoint Projects, Full-stack Expertise, Agile Methodologies, Project Collaboration",
      },
    ],
    portInfoList2: [
      {
        infoCap: "Strategic SharePoint Development",
      },
      {
        infoCap: "Comprehensive Full-Stack Support",
      },
      {
        infoCap: "Diverse Project Portfolio",
      },
      {
        infoCap: "Effective Collaboration and Best Practices",
      },
      {
        infoCap: "Agile Project Management",
      },
    ],
  },
  {
    id: 3,
    portImg: PortImg3,
    portTitle: "Software Developer",
    portComments: "0",
    portLikes: "0",
    portCateList: [
      {
        cateItem: "Manufacturing,",
        cateLink: "#",
      },
      {
        cateItem: "Technology",
        cateLink: "#",
      }
    ],
    portDesc:
      "Spearheaded the development of the RSC application, transforming it into an indispensable backbone of departmental meetings",
    portDesc2:
      "Demonstrated proficiency as a full-stack developer, actively engaged with end-users to gather requirements and created detailed designs",
    portDesc3:
      "Ensured rigorous unit testing, delivered effective training sessions, and maintained seamless communication with various departments",
    portDesc4:
      "Successfully implemented projects such as Dispatching and Invoicing Process, Shipping Expenses Management, Lumber's Yield Management, and New Process Development",
    portDesc5:
      "Developed informative dashboards with visual charts to track targets and actual figures",
    portDesc6:
      "Prioritized efficiency in resolving system-wide issues and bugs",
    portTitle2: "Key Achievements",
    portInfoList: [
      {
        infoItem: "Website:",
        infoCap: "rochdalespears.com",
      },
      {
        infoItem: "Date:",
        infoCap: "Sep 2016",
      },
      {
        infoItem: "Keywords:",
        infoCap: "MRP, ERP, Application Development, Full-stack Development, Dashboard Visualization",
      },
    ],
    portInfoList2: [
      {
        infoCap: "Strategic Transformation",
      },
      {
        infoCap: "User-Centric Development",
      },
      {
        infoCap: "Comprehensive Project Management",
      },
      {
        infoCap: "Diverse Project Portfolio",
      },
      {
        infoCap: "Data-driven Decision Making",
      },
    ],
  },
  {
    id: 4,
    portImg: PortImg4,
    portTitle: "Thesis Researcher",
    portComments: "0",
    portLikes: "0.2",
    portCateList: [
      {
        cateItem: "Healthcare,",
        cateLink: "#",
      },
      {
        cateItem: "Technology,",
        cateLink: "#",
      },
      {
        cateItem: "Biotechnology",
        cateLink: "#",
      },
    ],
    portDesc:
      "Electrocardiogram (ECG) signals record the heart's electrical activity and are vital for medical research and diagnostics.",
    portDesc2:
      "An increasing number of publicly available ECG signal databases exist, but they differ in data quality and representation format.",
    portDesc3:
      "These differences make it challenging to merge the databases for use in modern machine learning approaches that require large training data.",
    portDesc4:
      "This research aims to design and implement a data organization and formatting system with a standardized abstract import interface.",
    portDesc5:
      "The system allows importing and storing ECG databases from multiple sources through defined libraries conforming to the interface.",
    portDesc6:
      "Users can flexibly define and export data subsets in a unified format with identical, user-definable signal properties.",
    portDesc7:
      "The exported data in Hierarchical Data Format (HDF5) is accessible across platforms and programming languages, with output characteristics described in a reusable template.",
    portTitle2: "How it works",
    portInfoList: [
      {
        infoItem: "Website:",
        infoCap: "researchgate.net/publication/366603847",
      },
      {
        infoItem: "Date:",
        infoCap: "June 2022",
      },
      {
        infoItem: "Keywords:",
        infoCap: "ECG, QRS Detection, QRS-Complex, Machine Learning",
      },
    ],
    portInfoList2: [
      {
        infoCap: "Standard Import Interface",
      },
      {
        infoCap: "Flexible Data Organization",
      },
      {
        infoCap: "Unified Output Format",
      },
    ],
  },
  {
    id: 5,
    portImg: PortImg5,
    portTitle: "Anaylsis Application",
    portComments: "12",
    portLikes: "16",
    portCateList: [
      {
        cateItem: "Design,",
        cateLink: "#",
      },
      {
        cateItem: "Events,",
        cateLink: "#",
      },
      {
        cateItem: "Technology",
        cateLink: "#",
      },
    ],
    portDesc:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Home renovations, especially those involving plentiful of demolition can be a very dusty affair. The same is true as we experience the emotional sensation of stress from our first instances of social rejection ridicule. We quickly learn to fear and thus automatically avoid potentially stressful situations of all kinds of all making mistakes.",
    portDesc2:
      "Whether article spirits new her covered hastily sitting her. Money witty books nor son add. Chicken age had evening believe but proceed pretend mrs. At missed advice my it no sister. Miss told ham dull knew see she spot near can. Spirit her entire her called. Lorem ipsum dolor sit amet, consec tetur adipiscing elit. Ut elit tellus, luctus nec ullamcorper mattis, pulvinar. Lorem ipsum dolor sit amet, consec tetur adipiscing elit. Ut elit tellus, luctus nec ullamcorper mattis, pulvinar.",
    portDesc3:
      "Objectively pursue diverse catalysts for change for interoperable meta-services. Distinctively re-engineer revolutionary meta-services and premium architectures. Intrinsically incubate intuitive opportunities and real-time potentialities. Appropriately communicate one-to-one technology.",
    portDesc4:
      "The advantage of its Latin origin and the relative meaninglessness of Lorum Ipsum is that the text does not attract attention to itself or distract the viewer's attention from the layout.",
    portTitle2: "What makes the best co-working space?",
    portInfoList: [
      {
        infoItem: "Website:",
        infoCap: "websroad.com",
      },
      {
        infoItem: "Date:",
        infoCap: "August 02, 2022",
      },
      {
        infoItem: "Services:",
        infoCap: "Design, Art Direction, Website",
      },
    ],
    portInfoList2: [
      {
        infoCap: "Quis Nostrud Exercitation Ullamco Laboris.",
      },
      {
        infoCap: "Ullamco Laboris Nisi Ut Aliquip.",
      },
      {
        infoCap: "Reprehenderit In Voluptate Velit.",
      },
      {
        infoCap: "Accusantium Doloremque Laudantium.",
      },
      {
        infoCap: "Voluptate Velit Esse Cillum.",
      },
    ],
  },
  {
    id: 6,
    portImg: PortImg6,
    portTitle: "Business Growth",
    portComments: "35",
    portLikes: "40",
    portCateList: [
      {
        cateItem: "Design,",
        cateLink: "#",
      },
      {
        cateItem: "Events,",
        cateLink: "#",
      },
      {
        cateItem: "Technology",
        cateLink: "#",
      },
    ],
    portDesc:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Home renovations, especially those involving plentiful of demolition can be a very dusty affair. The same is true as we experience the emotional sensation of stress from our first instances of social rejection ridicule. We quickly learn to fear and thus automatically avoid potentially stressful situations of all kinds of all making mistakes.",
    portDesc2:
      "Whether article spirits new her covered hastily sitting her. Money witty books nor son add. Chicken age had evening believe but proceed pretend mrs. At missed advice my it no sister. Miss told ham dull knew see she spot near can. Spirit her entire her called. Lorem ipsum dolor sit amet, consec tetur adipiscing elit. Ut elit tellus, luctus nec ullamcorper mattis, pulvinar. Lorem ipsum dolor sit amet, consec tetur adipiscing elit. Ut elit tellus, luctus nec ullamcorper mattis, pulvinar.",
    portDesc3:
      "Objectively pursue diverse catalysts for change for interoperable meta-services. Distinctively re-engineer revolutionary meta-services and premium architectures. Intrinsically incubate intuitive opportunities and real-time potentialities. Appropriately communicate one-to-one technology.",
    portDesc4:
      "The advantage of its Latin origin and the relative meaninglessness of Lorum Ipsum is that the text does not attract attention to itself or distract the viewer's attention from the layout.",
    portTitle2: "What makes the best co-working space?",
    portInfoList: [
      {
        infoItem: "Website:",
        infoCap: "websroad.com",
      },
      {
        infoItem: "Date:",
        infoCap: "August 02, 2022",
      },
      {
        infoItem: "Services:",
        infoCap: "Design, Art Direction, Website",
      },
    ],
    portInfoList2: [
      {
        infoCap: "Quis Nostrud Exercitation Ullamco Laboris.",
      },
      {
        infoCap: "Ullamco Laboris Nisi Ut Aliquip.",
      },
      {
        infoCap: "Reprehenderit In Voluptate Velit.",
      },
      {
        infoCap: "Accusantium Doloremque Laudantium.",
      },
      {
        infoCap: "Voluptate Velit Esse Cillum.",
      },
    ],
  },
  {
    id: 7,
    portImg: PortImg7,
    portTitle: "Profitable Business",
    portComments: "88",
    portLikes: "55",
    portCateList: [
      {
        cateItem: "Design,",
        cateLink: "#",
      },
      {
        cateItem: "Events,",
        cateLink: "#",
      },
      {
        cateItem: "Technology",
        cateLink: "#",
      },
    ],
    portDesc:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Home renovations, especially those involving plentiful of demolition can be a very dusty affair. The same is true as we experience the emotional sensation of stress from our first instances of social rejection ridicule. We quickly learn to fear and thus automatically avoid potentially stressful situations of all kinds of all making mistakes.",
    portDesc2:
      "Whether article spirits new her covered hastily sitting her. Money witty books nor son add. Chicken age had evening believe but proceed pretend mrs. At missed advice my it no sister. Miss told ham dull knew see she spot near can. Spirit her entire her called. Lorem ipsum dolor sit amet, consec tetur adipiscing elit. Ut elit tellus, luctus nec ullamcorper mattis, pulvinar. Lorem ipsum dolor sit amet, consec tetur adipiscing elit. Ut elit tellus, luctus nec ullamcorper mattis, pulvinar.",
    portDesc3:
      "Objectively pursue diverse catalysts for change for interoperable meta-services. Distinctively re-engineer revolutionary meta-services and premium architectures. Intrinsically incubate intuitive opportunities and real-time potentialities. Appropriately communicate one-to-one technology.",
    portDesc4:
      "The advantage of its Latin origin and the relative meaninglessness of Lorum Ipsum is that the text does not attract attention to itself or distract the viewer's attention from the layout.",
    portTitle2: "What makes the best co-working space?",
    portInfoList: [
      {
        infoItem: "Website:",
        infoCap: "websroad.com",
      },
      {
        infoItem: "Date:",
        infoCap: "August 02, 2022",
      },
      {
        infoItem: "Services:",
        infoCap: "Design, Art Direction, Website",
      },
    ],
    portInfoList2: [
      {
        infoCap: "Quis Nostrud Exercitation Ullamco Laboris.",
      },
      {
        infoCap: "Ullamco Laboris Nisi Ut Aliquip.",
      },
      {
        infoCap: "Reprehenderit In Voluptate Velit.",
      },
      {
        infoCap: "Accusantium Doloremque Laudantium.",
      },
      {
        infoCap: "Voluptate Velit Esse Cillum.",
      },
    ],
  },
];
