import { ReactComponent as HappyCustomersIcon } from "../lib/icons/HappyCustomers.svg";
import { ReactComponent as FinishedProjectsIcon } from "../lib/icons/FinishedProjects.svg";
import { ReactComponent as CoffeeCupIcon } from "../lib/icons/CoffeeCup.svg";
import { ReactComponent as WorkingHoursIcon } from "../lib/icons/WorkingHours.svg";

export const funFacts = [
  {
    factIcon: <WorkingHoursIcon className="w-[3.125rem] fill-accent"></WorkingHoursIcon>,
    factCount: "8.5",
    factCap: "Years of Experience",
  },
  {
    factIcon: <FinishedProjectsIcon className="w-[3.125rem] fill-accent"></FinishedProjectsIcon>,
    factCount: "7",
    factCap: "Industry Sectors",
  },
  {
    factIcon: <HappyCustomersIcon className="w-[3.125rem] fill-accent"></HappyCustomersIcon>,
    factCount: "12",
    factCap: "Countries Reached",
  },
  {
    factIcon: <CoffeeCupIcon className="w-[3.125rem] fill-accent"></CoffeeCupIcon>,
    factCount: "6",
    factCap: "Awards/Recognitions",
  },
];
