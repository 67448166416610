import { ReactComponent as HomeIcon } from "../lib/icons/Home.svg";
import { ReactComponent as EnvelopeIcon } from "../lib/icons/Envelope.svg";
import { ReactComponent as PhoneIcon } from "../lib/icons/Phone.svg";

export const conInfo = [
  {
    conIcon: <HomeIcon className="h-[3.4375rem] fill-white"></HomeIcon>,
    conTitle: "Melbourne Central:",
    conCap: "Melbourne VIC 3000",
  },
  {
    conIcon: <PhoneIcon className="h-[3.4375rem] fill-white"></PhoneIcon>,
    conTitle: "Phone:",
    conCap: "+61 (04) 356 32 XYZ",
  },
  {
    conIcon: <EnvelopeIcon className="h-[3.4375rem] fill-white"></EnvelopeIcon>,
    conTitle: "Email Address:",
    conCap: "dandinh1903@gmail.com",
  },
];
