import React from "react";
import { Helmet } from "react-helmet";
import { BottomBar2 } from "../../components/BottomBar";
import { ContactUs } from "../../components/ContactUs";
import { Header2 } from "../../components/Header";
import { PageTitle } from "../../components/PageTitle";

const ContactPage = () => {
  return (
    <React.Fragment>
      <Helmet>
        <title>Contact - Dan Dinh Portfolio</title>
      </Helmet>
      <Header2></Header2>
      <PageTitle
        title="Contact Me"
        hometitle="Home"
        homeURL="/"
        currentPage="Contact Me"
      ></PageTitle>
      <section className="pageWrap relative w-full">
        <iframe className="w-full relative border-0 -mt-[8.125rem] min-h-[35rem] sm:min-h-[45rem] md:min-h-[60.3125rem]"
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d12608.64783981493!2d144.95746487578265!3d-37.80967522786451!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x6ad642cae530b1c9%3A0x59444c336aced016!2sMelbourne%20Central!5e0!3m2!1sen!2sau!4v1709590630340!5m2!1sen!2sau"
          allowfullscreen
          loading="lazy" title="Contact Map"
        ></iframe>
        {/* Page Wrap */}
      </section>
      <ContactUs></ContactUs>
      {/* <ContactInfoProfiles2></ContactInfoProfiles2> */}
      <BottomBar2></BottomBar2>
    </React.Fragment>
  );
};

export default ContactPage;
