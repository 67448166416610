import {
  FaEnvelope,
  FaLinkedinIn,
  FaSkype,
} from "react-icons/fa";

export const social3 = [
  {
    socialClass:
      "h-[2.8125rem] w-[2.8125rem] inline-flex items-center justify-center rounded-full bg-gray z-[1] before:absolute before:rounded-full before:inset-0 before:scale-50 hover:before:scale-100 before:opacity-0 before:z-[-1] hover:before:bg-[#1877F2] hover:before:opacity-100 group relative",
    socialIcon: <FaLinkedinIn className="fill-accent2 group-hover:fill-white" size={16}></FaLinkedinIn>,
    socialLink: "https://www.linkedin.com/in/huyquangdinh/",
    socialTitle: "LinkedIn",
  },
  {
    socialClass:
      "h-[2.8125rem] w-[2.8125rem] inline-flex items-center justify-center rounded-full bg-gray z-[1] before:absolute before:rounded-full before:inset-0 before:scale-50 hover:before:scale-100 before:opacity-0 before:z-[-1] hover:before:bg-[#009FDD] hover:before:opacity-100 group relative",
    socialIcon: <FaSkype className="fill-accent2 group-hover:fill-white" size={16}></FaSkype>,
    socialLink: "live:huydq1903",
    socialTitle: "Skype",
  },
  {
    socialClass:
      "h-[2.8125rem] w-[2.8125rem] inline-flex items-center justify-center rounded-full bg-gray z-[1] before:absolute before:rounded-full before:inset-0 before:scale-50 hover:before:scale-100 before:opacity-0 before:z-[-1] hover:before:bg-[#016DBF] hover:before:opacity-100 group relative",
    socialIcon: (
      <FaEnvelope className="fill-accent2 group-hover:fill-white" size={16}></FaEnvelope>
    ),
    socialLink: "mailto:dandinh1903@gmail.com",
    socialTitle: "Mail",
  },
];
