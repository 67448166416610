import PortImg1 from "../assets/images/resources/portImg1-1.jpg";
import PortImg2 from "../assets/images/resources/portImg1-2.jpg";
import PortImg3 from "../assets/images/resources/portImg1-3.png";
import PortImg4 from "../assets/images/resources/portImg1-4.jpg";

export const portfolio = [
  {
    id: 1,
    portBox: "basis-full md:basis-[50%] px-[.9375rem]",
    portImg: PortImg1,
    portTitle: "Business Analyst",
    portSubTitle: "ELCA Vietnam",
    portcate: "analysis",
  },
  {
    id: 2,
    portBox: "basis-full md:basis-[50%] px-[.9375rem]",
    portImg: PortImg2,
    portTitle: "Senior Software Engineer",
    portSubTitle: "ELCA Vietnam",
    portcate: "development",
  },
  {
    id: 3,
    portBox: "basis-full md:basis-[50%] px-[.9375rem]",
    portImg: PortImg3,
    portTitle: "Software Developer",
    portSubTitle: "Rochdale Spears Group",
    portcate: "development, erp",
  },
  {
    id: 4,
    portBox: "basis-full md:basis-[50%] px-[.9375rem]",
    portImg: PortImg4,
    portTitle: "Thesis Researcher",
    portSubTitle: "Heilbronn University",
    portcate: "research",
  },
];
